import { FolderIcon } from "@heroicons/react/outline"

const FeedbackPageHeader = ({feedback}) => {

  return (
    <div className="dashboardheader">
      <div className="head">
        <h4 className="h4">{feedback.title}</h4>
        <small>
          { feedback.description && feedback.description.length > 100
            ? `${feedback.description.substring(0, 97)}...`
            : feedback.description}
        </small>
      </div>

      <div className="stats">
        <div className="stat">
          <FolderIcon />

          <div>
            <h5 className="h5">{feedback.num_of_submissions || 0}</h5>
            <small>Submissions</small>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeedbackPageHeader
