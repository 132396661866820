import { DocumentAddIcon } from "@heroicons/react/solid"
import { useEffect, useRef, useState } from "react"
import AdminFeedbacksCard from "./components/AdminFeedbacksCard"
import CreateNewModal from "./CreateNewModal"

import "./AdminFeedbacks.scss"
import { Admin, Feedback } from "../../../api"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

const allFeedbacks = [
  {
    name: "Institution Feedback",
    createdAt: "Jan 4, 2023",
    updatedAt: "3 days ago",
  },
  {
    name: "Department Feedback",
    createdAt: "Jan 4, 2023",
    updatedAt: "3 days ago",
  },
  {
    name: "Course Feedback",
    createdAt: "Jan 4, 2023",
    updatedAt: "3 days ago",
  },
]

const AdminFeedbacks = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [feedbackTypes, setFeedbackTypes] = useState([]);
  const [searchResults, setSearchResults] = useState(feedbackTypes);

  const searchInput = useRef()

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  const handleSearch = () => {
    const input = searchInput.current.value.trim().toLowerCase()

    const filteredResults = allFeedbacks.filter((f) =>
      f.name.toLowerCase().includes(input)
    )

    setSearchResults(filteredResults)
  }

  useEffect(() => {
    Admin.getFeedbackTypes().then((res) => {
      if (res.type && res.type !== "error") {
        setFeedbackTypes(res.feedbackTypes);
      }
    }).catch((err) => {
      const resp = err.response?.data || {message: "Server error"};
      toast.error(resp.message, {autoClose: 2000});
      navigate("/");
    })
  }, [])

  return (
    <div className="feedback-admin">
      <div className="head">
        <h2 className="h2 tracking-tighter">Feedbacks</h2>

        <button
          className="create-new"
          onClick={() => {
            openModal()
          }}
        >
          <DocumentAddIcon className="h-5" />
          Create new
        </button>
      </div>

      <CreateNewModal isOpen={isOpen} closeModal={closeModal} />

      <div className="flex flex-col gap-y-4">
        {/* <div className="relative">
          <label htmlFor="searchFeedbacks" className="sr-only">
            Search
          </label>
          <input
            id="searchFeedbacks"
            className="w-full text-gray-800 border-0 focus:ring-transparent placeholder-gray-400 appearance-none py-3 pl-10 pr-4 ring-1 ring-gray-400 rounded-full"
            type="search"
            placeholder="Search…"
            ref={searchInput}
            onChange={handleSearch}
          />

          <button
            className="absolute inset-0 right-auto group"
            aria-label="Search"
            onClick={handleSearch}
          >
            <svg
              className="w-4 h-4 shrink-0 fill-current text-gray-400 group-hover:text-gray-500 ml-4 mr-2"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
              <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
            </svg>
          </button>
        </div> */}

        <span className="text-lg text-gray-500">All feedbacks</span>

        <table className="table">
          <tr className="bg-sky-700 text-white w-full">
            <th>Name</th>
            <th>Total Submissions</th>
            <th>Last Submission</th>
            {/* <th>Created At</th> */}
            <th>Last Modified</th>
            <th></th>
          </tr>

          {feedbackTypes.length !== 0 &&
            feedbackTypes.map((f) => (
              <AdminFeedbacksCard
                key={f.id}
                name={f.title}
                submissions={f.submissions}
                last_submission={f.last_submission && new Date(f.last_submission.date)}
                createdAt={f.date_added}
                updatedAt={f.last_update}
                id={f.id}
              />
            ))}
        </table>

        {feedbackTypes.length === 0 && <small>No results</small>}
      </div>
    </div>
  )
}

export default AdminFeedbacks
