import { DocumentAddIcon } from "@heroicons/react/solid"
import { useState } from "react"
import { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { Admin } from "../../../api"
import FeedbackCard from "./components/FeedbackCard"

import "./Feedback.scss"

const AddAdmin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [form, setForm] = useState({fname: "", lname: "", email: "", phone: "", role: "", password: "", cpassword: ""});


  const handleChange = e => {
    setForm({...form, [e.target.name]: e.target.value});
  }

  const submitForm = e => {
    e.preventDefault();
    if (form.password !== form.cpassword) {
        return toast.error("Password and confirm password do not match", {autoClose: 2000});
    }
    Admin.addAdmin(form).then((res) => {
        toast.success("Admin added successfully", {autoClose: 2000});
        navigate("/admins");
    }).catch(err => {
        const resp = err.response?.data || {message: "Server error"};
        toast.error(resp.message, {autoClose: 2000});
    })
  }


  return (
    <div className="feedbacks">
      <div className="head">
        <h1 className="h1">Add New Admin</h1>
      </div>

      {/* <div className="col-9">
        <div className="mt-2">
            <input
            className="py-2 px-4 ring-1 ring-gray-200 w-full rounded-lg"
            placeholder="Enter feedback title..."
            name="title"
            value={form.title}
            onChange={inputChange}
            />
        </div>
        <div className="mt-3">
            <textarea 
            className="py-2 px-4 ring-1 ring-gray-200 w-full rounded-lg"
            id="" 
            cols="30" rows="5"
            placeholder="Enter Feedback description.."
            name="description"
            value={form.description}
            onChange={inputChange}
            ></textarea>
        </div>
        <div className="mt-3">
            <label htmlFor="submittable">Who can submit a response?</label>
            <select 
            className="py-2 px-4 ring-1 ring-gray-200 w-full rounded-lg"
            id="submittable"
            name="submittable_by"
            value={form.submittable_by}
            onChange={inputChange}
            >
            <option value="everyone">Everyone</option>
            <option value="reg_users">Only All Registered Users</option>
            <option value="undergraduates">Only Undergraduate Registered Users</option>
            <option value="postgraduates">Only Postgraduate Registered Users</option>
            </select>
        </div>

        <div className="mt-4 flex flex-wrap gap-2 items-center justify-between">
            <button
            type="button"
            className="inline-flex justify-center rounded-md border border-transparent bg-black px-4 py-2 text-sm font-medium text-gray-50 hover:bg-gray-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
            onClick={submitForm}
            >
            {loading ? "Creating..." : "Create"}
            </button>
        </div>
      </div> */}

      
    <div className="flex flex-row flex-wrap gap-10">

        <div className="flex flex-col gap-y-1 flex-grow items-start">
          <label className="text-gray-500 text-sm" htmlFor="fname">
            First Name
          </label>
          <input
            className="py-2 px-3 md:min-w-[300px] bg-gray-50 rounded-lg border-[1px] border-gray-200 w-full"
            type="text"
            name="fname"
            id="fname"
            onChange={handleChange}
            value={form.fname}
            placeholder="First Name"
            required
          />
        </div>

        <div className="flex flex-col gap-y-1 flex-grow items-start">
          <label className="text-gray-500 text-sm" htmlFor="lname">
            Last Name
          </label>
          <input
            className="py-2 px-3 md:min-w-[300px] bg-gray-50 rounded-lg border-[1px] border-gray-200 w-full"
            type="text"
            name="lname"
            id="lname"
            onChange={handleChange}
            value={form.lname}
            placeholder="Last Name"
            required
          />
        </div>

        <div className="flex flex-col gap-y-1 flex-grow items-start">
          <label className="text-gray-500 text-sm" htmlFor="email">
            Email Address
          </label>
          <input
            className="py-2 px-3 md:min-w-[300px] bg-gray-50 rounded-lg border-[1px] border-gray-200 w-full"
            type="email"
            name="email"
            id="email"
            onChange={handleChange}
            value={form.email}
            placeholder="Email Address"
            required
          />
        </div>

        <div className="flex flex-col gap-y-1 flex-grow items-start">
          <label className="text-gray-500 text-sm" htmlFor="role">
            Admin Role
          </label>
          <select
          className="py-2 px-3 md:min-w-[300px] bg-gray-50 rounded-lg border-[1px] border-gray-200 w-full"
          name="role"
          id="role"
          onChange={handleChange}
          required
          >
            <option value={""}>-Select-</option>
            <option value={"admin"}> Admin</option>
            <option value={"superadmin"}>Superior Admin</option>
          </select>
        </div>

        <div className="flex flex-col gap-y-1 flex-grow items-start">
          <label className="text-gray-500 text-sm" htmlFor="password">
            Password (They'll change this before login)
          </label>
          <input
            className="py-2 px-3 md:min-w-[300px] bg-gray-50 rounded-lg border-[1px] border-gray-200 w-full"
            type="password"
            name="password"
            id="password"
            onChange={handleChange}
            value={form.password}
            placeholder="Password"
            required
          />
        </div>
        
        <div className="flex flex-col gap-y-1 flex-grow items-start">
          <label className="text-gray-500 text-sm" htmlFor="cpassword">
            Confirm Password
          </label>
          <input
            className="py-2 px-3 md:min-w-[300px] bg-gray-50 rounded-lg border-[1px] border-gray-200 w-full"
            type="password"
            name="cpassword"
            id="cpassword"
            onChange={handleChange}
            value={form.cpassword}
            placeholder="Confirm Password"
            required
          />
        </div>

        <button
        type="button"
        className="inline-flex justify-center rounded-md border border-transparent bg-black px-4 py-2 text-sm font-medium text-gray-50 hover:bg-gray-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2 w-full"
        onClick={submitForm}
        >
        {loading ? "Registering..." : "Register as admin"}
        </button>
        
      </div>
    </div>
  )
}

export default AddAdmin;
