import { useEffect, useRef, useState } from "react"
import AdminFeedbacksCard from "./components/AdminFeedbacksCard"

import "./AdminFeedbacks.scss"
import { Admin, Feedback, Institution } from "../../../api"
import { Link, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import DataTable  from "react-data-table-component";
import { ChartBarIcon } from "@heroicons/react/solid"

const dateSort = (rowA, rowB) =>{ 
  return new Date(rowA.date).getTime() - new Date(rowB.date).getTime();
}

const customStyles = {
	headRow: {
		style: {
			border: 'none',
      backgroundColor: "rgba(3, 105, 161, 1)",
		},
	},
  rows: {
		highlightOnHoverStyle: {
			backgroundColor: 'rgb(230, 244, 244)',
			borderBottomColor: '#FFFFFF',
			outline: '1px solid #FFFFFF',
		}
	},
	headCells: {
		style: {
			color: '#fff',
			fontSize: '0.8rem',
		},
	},
	pagination: {
		style: {
			border: 'none',
		},
	}

};

const FeedbackSubmissions = () => {
  const navigate = useNavigate();
  const [feedbackType, setFeedbackType] = useState({});
  const [submissions, setSubmissions] = useState([]);
  const [schools, setSchools] = useState([]);
  const params = useParams();
  
  const columns = [
    { name: 'Submitted by', selector: row => row.user_type === "anonymous" ? "Anonymous user" : row.user, sortable: true },
    { name: 'Institution', selector: 'institution', sortable: true },
    { name: 'Submission Date', selector: row => new Date(row.date).toDateString(), sortable: true, sortFunction: dateSort },
    { name: 'Action', selector: (row) => <Link to={`/feedbacks/${params.id}/submissions/${row.id}`}>View Details &gt;&gt;</Link> },
  ];

  useEffect(() => {
    
    Institution.getAll()
    .then(res => {
      setSchools(res.schools);
    })

    Feedback.getSubmissions({feedback_id: params.id})
    .then((res) => {
      setSubmissions(res.submissions);
      setFeedbackType(res.feedbackType)
    })
    .catch((err) => {
      const resp = err.response?.data || {message: "Server error"};
      toast.error(resp.message, {autoClose: 2000});
      navigate("/");
    })

  }, [])

  return (
    <div className="feedback-admin">
      <u>
        <Link to={"/feedbacks"}>&lt;&lt; Back to feedbacks</Link>
      </u>
      <div className="head">
        <h2 className="h2 tracking-tighter">{feedbackType.title} Submissions</h2>
        <Link
        to={"/feedbacks/"+ params.id +"/summary"}
          className="create-new"
        >
          <ChartBarIcon className="h-5" />
          View summary
        </Link>
      </div>

      <div className="flex flex-col gap-y-4">
        
        <div className="col-9">
          <p></p>
          <input type="text" className="form-control" />
        </div>

        <span className="text-lg text-gray-700">Total Submissions: {submissions.length}</span>

        
      <div className="table_container">
        <DataTable
        data={
          submissions.map(s => ({...s, institution: schools.find(sch => sch.id === parseInt(s.institution))?.name}))
        }
        columns={columns}
        customStyles={customStyles}
        pagination
        paginationComponentOptions={
          {selectAllRowsItem: true, selectAllRowsItemText: "Show all"}
        }
        />
      </div>

        {submissions.length === 0 && <small>No results</small>}
      </div>
    </div>
  )
}

export default FeedbackSubmissions
