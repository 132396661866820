import { Tab } from "@headlessui/react"
import { useNavigate, useParams } from "react-router-dom";
import { Feedback, Institution, User } from "../../../api";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import SummaryChart from "./components/SummaryChart";
import Loader from "../../../components/Loader";

const SubmissionsSummary = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [feedback, setFeedback] = useState({});
  const [form, setForm] = useState({school: "", department: "", faculty: "", dateFrom: "", dateTo: "", userType: "" });
  const [formDate, setFormDate] = useState('');
  const [schools, setSchools] = useState([]);
  const [faculties, setFaculties] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Institution.getAll()
    .then(res => {
      setSchools(res.schools);
    })

    Institution.getAllDepartments()
    .then(res => {
      setDepartments(res.departments);
    })

    Feedback.getSubmissionsSummary(params.id)
    .then(res => {
      setLoading(false);
      setResponses(res.summary);
      setFeedback(res.feedback);
    })
    .catch(err => {
      setLoading(false)
      const resp = err.response?.data || {message: "Network error"};
      toast.error(resp.message, {autoClose: 2000});
    })

  }, [])

  const inputChange = (e) => {
    if (e.target.name === "school") {
      setFaculties([]);
      setDepartments([]);
      setForm({...form, school: e.target.value});
      if (e.target.value !== "") {
        Institution.getDepartments({school_id: e.target.value})
        .then((res) => {
          console.log(res.departments);
          setDepartments(res.departments);
        })
        .catch((err) => {
          const error = err.response?.data
          toast.error("Cannot fetch school faculties: " + error.message, {autoClose: 2000});
        })
      }

    } else if (e.target.name === "faculty") {
      setDepartments([]);
      setForm({...form, faculty: e.target.value});
      if (e.target.value !== "") {
        Institution.getFacultyDepartments(e.target.value)
        .then((res) => {
          setDepartments(res.departments);
        })
        .catch(() => {
          toast.error("Cannot fetch faculty departments", {autoClose: 2000});
        })
      }
    } else {
      setForm({...form, [e.target.name] : e.target.value});
    }
  }

  const filterData = () => {
    setLoading(true);
    console.log(form);
    Feedback.getSubmissionsSummary(params.id, form)
    .then(res => {
      setLoading(false);
      setResponses(res.summary);
    })
    .catch(err => {
      setLoading(false);
      const resp = err.response?.data || {message: "Network error"};
      toast.error(resp.message, {autoClose: 2000});
    })
  }

  return (
    <div className="w-full p-5">
    {loading && (
      <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-50">
        <Loader type="large" dark />
      </div>
    )}
      <button onClick={() => navigate(-1)}><u>&lt;&lt;Go Back</u></button>
      <h2 className="h2 border-b py-5 border-gray-200"> {feedback.title} Summary </h2>

      <div className="w-full px-5 py-4 flex flex-wraps gap-2 justify-around items-center">
        <div className="w-full md:w-[45%]">
          <p>Display Summary for only:</p>
          <select name="school" id="" className="form-control" onChange={inputChange}>
            <option value={""}>All schools</option>
            {
              schools.map((sch) => <option value={sch.id} key={sch.id}>{sch.name}</option>)
            }
          </select>
        </div>

        <div className="w-full md:w-[45%]">
          <p>Select Department:</p>
          <select name="department" id="" className="form-control" onChange={inputChange}>
            <option value={""}>All Departments</option>
            {
              departments.map((dept) => <option value={dept.dept_id} key={dept.dept_id}>{dept.name}</option>)
            }
          </select>
        </div>
      </div>

      <div className="w-full px-5 py-4 flex flex-wrap gap-2 justify-between items-center">
        <div>
          <p>User Type</p>
          <select name="userType" id="" className="form-control" onChange={inputChange}>
            <option value={""}>All Users</option>
            <option value={"anonymous"}>Anonymous Users</option>
            <option value={"registered"}>Registered Users</option>
          </select>
        </div>

        <div>
          <p>Period</p>
          <select name="period" id="" className="form-control" onChange={(e) => setFormDate(e.target.value)}>
            <option value={""}>All Time</option>
            <option value={"select"} >Select Date</option>
          </select>

          {
            formDate === "select" &&
            <div className="flex gap-2">
              <div>
                <p>From:</p>
                <input type="date" name="dateFrom" className="form-control" onChange={inputChange} />
              </div>
              <div>
                <p>To:</p>
                <input type="date" name="dateTo" className="form-control" onChange={inputChange} />
              </div>
            </div>
          }

        </div>

        <button 
        className="bg-sky-600 py-3 px-5 font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500"
        onClick={filterData}
        >Filter Data</button>

      </div>

      <div className="w-full mt-1">
       {
        responses.map((res, i) => {
          return <SummaryChart response={res} key={i} i={i+1} schools={schools} departments={departments}/>
        })
       }
      </div>
    </div>
  )
}

export default SubmissionsSummary;
