import CardMenu from "./CardMenu";

const InstitutionCard = ({ name, id, submissions, last_submission, type, deleteSchool }) => {
  return (
    <tr className="card">
      <td>{name.length > 28 ? `${name.substring(0, 25)}...` : name}</td>

      <td>{submissions || 0}</td>
      <td>{ submissions ? `${new Date(last_submission).toDateString() } | ${new Date(last_submission).toLocaleTimeString()}` : "No submission yet"}</td>
      <td>{ type}</td>
      
      {/* <td>{ updatedAt.toString().slice(0, 10) === "0000-00-00" ? "No update yet" : `${new Date(updatedAt).toDateString()} | ${new Date(updatedAt).toLocaleTimeString()}` }</td> */}

      <td className="flex flex-wrap gap-4 text-sm">
        <CardMenu name={name} id={id} deleteSchool={deleteSchool} />
      </td>
    </tr>
  )
}

export default InstitutionCard
