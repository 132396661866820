import { ChartBarIcon, DocumentAddIcon } from "@heroicons/react/solid"
import { useState } from "react"
import { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { Institution, User } from "../../../api"
import DataTable  from "react-data-table-component";

import "./Feedback.scss"
import UserProfileButton from "./components/UserProfileButton"

const dateSort = (rowA, rowB) =>{ 
  return new Date(rowA.date).getTime() - new Date(rowB.date).getTime();
}

const customStyles = {
	headRow: {
		style: {
			border: 'none',
      backgroundColor: "rgba(3, 105, 161, 1)",
		},
	},
  rows: {
		highlightOnHoverStyle: {
			backgroundColor: 'rgb(230, 244, 244)',
			borderBottomColor: '#FFFFFF',
			outline: '1px solid #FFFFFF',
		}
	},
	headCells: {
		style: {
			color: '#fff',
			fontSize: '0.8rem',
		},
	},
	pagination: {
		style: {
			border: 'none',
		},
	}

};

const columns = [
  { name: 'Name', selector: row => row.fname + " " + row.lname, sortable: true },
  { name: 'Institution', selector: 'institution', sortable: true },
  { name: 'Submissions', selector: 'num_of_submissions', sortable: true },
  { 
    name: 'Last Submission',
    selector: row => row.num_of_submissions > 0 ? new Date(row.last_submission_date).toDateString() : "-----",
    sortable: true,
    sortFunction: dateSort
  },
  { name: 'Action', selector: 'action' },
];

const UsersList = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [schools, setSchools] = useState([]);

  useEffect(() => {
    Institution.getAll().then((res) => {
      setSchools(res.schools);
    }).catch((err) => {
      const resp = err.response?.data || {message: "Server error"};
      toast.error(resp.message, {autoClose: 2000});
      navigate("/");
    })

    User.getAll().then((res) => {
      setUsers(res.users);
    }).catch((err) => {
      const resp = err.response?.data || {message: "Server error"};
      toast.error(resp.message, {autoClose: 2000});
      navigate("/");
    })
  }, [])

  return (
    <div className="feedbacks">
      <div className="head">
        <h1 className="h1">Users</h1>

        <Link to="/users/summary" className="submit-feedback">
          <ChartBarIcon className="h-5" />
          View Summary
        </Link>
      </div>

      <div>
        <span className="text-lg text-gray-500">Total: {users.length?.toString().padStart(3, '0')}</span>

          <DataTable 
          columns={columns} 
          data={users.map((user) => ({...user, action: <UserProfileButton id={user.id} /> }))} 
          customStyles={customStyles}
          />


        <div className="cards">
        
        </div>
      </div>
    </div>
  )
}

export default UsersList;
