import CardMenu from "./CardMenu";

const FeedbackCard = ({ name, createdAt, updatedAt, id, submissions, last_submission }) => {
  return (
    <tr className="card">
      <td>{name.length > 28 ? `${name.substring(0, 25)}...` : name}</td>

      <td>{submissions}</td>
      <td>
        { 
        submissions ? `${new Date(last_submission).toDateString() } | ${new Date(last_submission).toLocaleTimeString()}` : "No submission yet" 
      }
      </td>
      {/* <td>{new Date(createdAt).toDateString()} | {new Date(createdAt).toLocaleTimeString()}</td> */}
      <td>{ updatedAt.toString().slice(0, 10) === "0000-00-00" ? "No update yet" : `${new Date(updatedAt).toDateString()} | ${new Date(updatedAt).toLocaleTimeString()}` }</td>

      <td className="flex flex-wrap gap-4 text-sm">
        <CardMenu name={name} id={id} />
      </td>
    </tr>
  )
}

export default FeedbackCard
