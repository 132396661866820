import http from "axios";
import Cookies from "js-cookie";

const serverurl = "https://serverapi.akadametrix.com";
// const serverurl = "http://localhost:5000";

const requestOptions = (datatype = null) => {
    let options = {};
    const token = Cookies.get("akada__admin");
    if (token) {
        options = {
            ...options,
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": datatype,
                "Access-Control-Allow-Origin": "*",
                "Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            }
        }
    }
    return options;
}

const response = (res) => res.data;

const request = {
    get: (url) => http.get(`${serverurl}${url}`, requestOptions("application/json") ).then(response),
    post: (url, data) => http.post(`${serverurl}${url}`, data, requestOptions("application/json")).then(response),
    put:(url, data)=> http.put(`${serverurl}${url}`, data, requestOptions("application/json")).then(response),
    delete:(url, data)=> http.delete(`${serverurl}${url}`, data, requestOptions("application/json")).then(response)
}

const Admin = {
    login: (params)=> request.post("/admin/login", params),
    changePassword: (params) => request.post("/admin/changePassword", params),
    profile: (params) => request.post("/admin/getProfile", params),
    allAdmins: (params) => request.post("/admin/allAdmins", params),
    addAdmin: (params) => request.post("/admin/addAdmin", params),
    getFeedbackTypes: (params)=> request.post("/admin/getFeedbackTypes", params),
    dashboardData: (params)=> request.get("/admin/dashboard", params),
}

const Feedback = {
    addFeedbackType: (params)=> request.post("/feedbacks/addFeedbackType", params),
    getFeedbackTypes: (params)=> request.post("/feedbacks/getTypes", params),
    getFeedbackQuestions: (params)=> request.post("/feedbacks/getQuestions", params),
    editQuestions: (params)=> request.post("/feedbacks/addQuestions", params),
    getResponses: (params)=> request.post("/feedbacks/getResponses", params),
    getSubmissions: (params)=> request.post("/feedbacks/getSubmissions", params),
    getSubmissionAnswers: (params)=> request.post("/feedbacks/submissionAnswers", params),
    getUserSubmissions: (params)=> request.post("/feedbacks/getUserSubmissions", params),
    getSubmissionsSummary: (feedbackId, body)=> request.post(`/feedbacks/${feedbackId}/summary`, body)
}

const Institution = {
    getAll: (params)=> request.get("/institutions", params),
    add: (params)=> request.post("/institutions", params),
    getSchoolDetails: (params)=> request.post("/institutions/schoolDetails", params),
    deleteSchool: (params)=> request.post("/institutions/delete", params),
    getDepartments: (params)=> request.post("/institutions/getDepartments", params),
    getAllDepartments: (params)=> request.post("/institutions/getAllDepartments", params),
    getSchoolFaculties: (schoolId)=> request.get(`/institutions/${schoolId}/faculties`),
    getFacultyDepartments: (facultyId)=> request.get(`/institutions/faculties/${facultyId}/departments`),
    addDepartments: (schoolId, facultyId, data)=> request.post(`/institutions/${schoolId}/faculties/${facultyId}/departments`, data),
    addFaculties: (schoolId, data)=> request.post(`/institutions/${schoolId}/faculties`, data),
    updateDepartment: (schoolId, deptId, data)=> request.put(`/institutions/${schoolId}/departments/${deptId}`, data),
}

const User = {
    getAll: (params)=> request.get("/users", params),
    getProfile: (params)=> request.post("/users/getProfile", params),
}

const Message = {
    getAll: (params)=> request.get("/messages", params),
    getOne: (msgId)=> request.get("/messages/" + msgId),
}

export {Admin, serverurl, Feedback, Institution, User, Message};