import { Link, useNavigate } from "react-router-dom"
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline"
import Helmet from 'react-helmet'
import { useState } from "react"
import Logo from "../../../assets/Logo"
import "../auth.scss"
import Loader from "../../../components/Loader";
import { Admin } from "../../../api";
import {toast} from "react-toastify";
import Cookies from "js-cookie"

const Login = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({email: "", password: ""});


  const inputChange = e => {
    setForm({...form, [e.target.name]: e.target.value });
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    Admin.login(form).then(res => {
      setLoading(false);
      if (!res.verified) {
        Cookies.set("akada__admin_unverified", res.token);
        toast.warning("You need to change password before login", {autoClose: 2000});
        return navigate("/change-password");
      }
      Cookies.set("akada__admin", res.token);
      navigate("/dashboard");
    }).catch(err => {
      console.log(err);
      setLoading(false);
      const resp = err.response?.data || {message: "Server error"};
      toast.error(resp.message, {autoClose: 2000});
    })

  }

  return (
    <>
      <Helmet>
        <title>Log in | Acadametrics Admin</title>
      </Helmet>
      <div className="auth">

        <form className="auth-form" onSubmit={handleSubmit}>
          <Link className="self-center block mb-8" to="/">
            <Logo className="h-10 logo" />
          </Link>

          <div className="auth-form__header">
            <h4 className="h4">Admin Login</h4>
            <span className="text-gray-400">
              Login with your assigned account details
            </span>
          </div>

          <div className="auth-field">
            <span>
              <label htmlFor="email">Email</label>
            </span>
            <input
              id="email"
              name="email"
              type="email"
              placeholder="example@example.com"
              required
              onChange={inputChange}
              value={form.email}
            />
          </div>

          <div className="auth-field forgot">
            <span className="forgot-label">
              <label htmlFor="password">Password</label>
              <span className="text-blue-500">Forgot password?</span>
            </span>

            <div>
              <input
                id="password"
                name="password"
                type={show ? "text" : "password"}
                placeholder="Choose a password"
                required
                onChange={inputChange}
                value={form.password}
              />

              <button className="btn" onClick={() => setShow(!show)} type="button">
                {show ? (
                  <EyeOffIcon className="h-4" type="button" />
                ) : (
                  <EyeIcon className="h-4" type="button" />
                )}
              </button>
            </div>
          </div>

          <button className="submit-button" type="submit">
            {loading ? <Loader /> : 'Login' }
          </button>

        </form>
      </div>
    </>
  )
}

export default Login
