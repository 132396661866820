import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";

const customStyles = {
	headRow: {
		style: {
			border: 'none',
      backgroundColor: "rgba(3, 105, 161, 1)",
		},
	},
  rows: {
		highlightOnHoverStyle: {
			backgroundColor: 'rgb(230, 244, 244)',
			borderBottomColor: '#FFFFFF',
			outline: '1px solid #FFFFFF',
		}
	},
	headCells: {
		style: {
			color: '#fff',
			fontSize: '0.8rem',
		},
	},
	pagination: {
		style: {
			border: 'none',
		},
	}

};

const dateSort = (rowA, rowB) =>{ 
    return new Date(rowA.date).getTime() - new Date(rowB.date).getTime();
}
const columns = [
    { name: 'Feedback type', selector: row => row.feedback, sortable: true },
    { name: 'Submission Date', selector: row => new Date(row.date).toDateString(), sortable: true, sortFunction: dateSort },
    { name: 'Action', selector: (row) => <Link to={`/feedbacks/${row.feedbackId}/submissions/${row.submissionId}`}>View Details &gt;&gt;</Link> },
  ];

const UserSubmissions = ({submissions}) => {
    return (
        <div className="table_container">
			<h4 className="h4">Total: {submissions.length}</h4>
            <DataTable
            data={
                submissions
            }
            columns={columns}
            customStyles={customStyles}
            />
        </div>
    )
}

export default UserSubmissions;