import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/solid"
import React, { Fragment } from "react"

const AddDepartentModal = ({ isOpen, closeModal, school, form }) => {

  return (
    <Transition appear show={isOpen} as={Fragment} >
    <Dialog as="div" className="relative z-10" onClose={closeModal}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black bg-opacity-25" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Add New Faculties to {school?.name}
              </Dialog.Title>
              <p>Separate each faculty name with a comma (,)</p>
              <div className="mt-3">
                <textarea 
                className="py-2 px-4 ring-1 ring-gray-200 w-full rounded-lg"
                id="" 
                cols="30" rows="5"
                placeholder="Eg: Agriculture, Technology, Medicine"
                value={form.uniFaculties}
                onChange={(e) => form.facultyChange(e.target.value)}
                ></textarea>
              </div>

              <div className="mt-4 flex flex-wrap gap-2 items-center justify-between">
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent bg-black px-4 py-2 text-sm font-medium text-gray-50 hover:bg-gray-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                  onClick={form.submitForm}
                >
                  {form.loading ? "Adding..." : "Add Faculty"}
                </button>

                <button
                  type="button"
                  className="inline-flex items-center justify-center gap-2 rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-red-50 hover:bg-red-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                  onClick={() => {
                    closeModal()
                  }}
                >
                  <XIcon className="h-5" />
                  Close
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
    </Transition>
  )
}

export default AddDepartentModal
