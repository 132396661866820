import React, { useEffect } from "react"
import { SwitchVerticalIcon } from "@heroicons/react/outline"

import "./Dashboard.scss"
import DashboardChart from "../components/DashboardChart"
import { useState } from "react"
import DashboardHeader from "../components/DashboardHeader"
import { Admin, User } from "../../../api"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import Cookies from "js-cookie"

const monthsData = ["January",  "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [chartType, setChartType] = useState(0);
  const types = ["area", "bar"];
  const [admin, setAdmin] = useState({});
  const [data, setData] = useState({});
  const [users, setUsers] = useState([]);
  const [months, setMonths] = useState([]);
  
  useEffect(() => {
    const today = new Date();
    const allMonths = [];
    for (let i = 0; i < 6; i++) {
      const month = {
        title: monthsData[new Date(today.getFullYear(), today.getMonth() - i, 1).getMonth()],
        month: new Date(today.getFullYear(), today.getMonth() - i, 1).getMonth(),
        year: new Date(today.getFullYear(), today.getMonth() - i, 1).getFullYear()
      };
      
      allMonths.push(month);
    }
    setMonths(allMonths);
    
    Admin.profile().then(res => {
      setAdmin(res.admin);
    }).catch((err) => {
      const resp = err.response?.data || {message: "Server error"};
      toast.error(resp.message, {autoClose: 2000});
      Cookies.remove("akada__admin");
      navigate("/login");
    })

    Admin.dashboardData()
    .then(res => {
      setData(res);
    })
    .catch(err => {
      const resp = err.response?.data || {message: "Server error"};
      toast.error(resp.message, {autoClose: 2000});
      Cookies.remove("akada__admin");
      navigate("/login");
    })

    User.getAll({ from: new Date(new Date().getFullYear(), new Date().getMonth() - 6, 1), to: new Date() })
    .then(res => {
      const monthlyUsers = allMonths.map(month => {
        const users = res.users.filter(user => (new Date(user.date_registered).getMonth()) === month.month && new Date(user.date_registered).getFullYear() === month.year)
        return {...month, users: users.length};
      })
      setMonths(monthlyUsers);
      setUsers(res.users);
    })
    .catch(err => {
      console.log(err);
      toast.error("An error occurred", {autoClose: 2000});
    })

  }, [])

  const handleChartChange = () => {
    if (chartType === 0) {
      setChartType(chartType + 1)
      return
    }
    if (chartType === types.length - 1) {
      setChartType(0)
      return
    }
    setChartType(chartType + 1)
    return
  }

  useEffect(() => {
    console.log(chartType)
  }, [chartType])

  return (
    <div className="admindashboard">
      <DashboardHeader admin={admin} data={data} />

      <div className="content">

        <div className="chart">
          <div className="head">
            <h4>
              Verified users in the last 6 months.
            </h4>
            <button onClick={handleChartChange}>
              <span>{`${types[chartType].toUpperCase()} CHART`}</span>
              <SwitchVerticalIcon className="h-5" />
            </button>
          </div>
          <DashboardChart type={types[chartType]} data={months.sort((a,b) => a.month - b.month)} />
        </div>

      </div>
    </div>
  )
}

export default AdminDashboard
