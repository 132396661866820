import { useEffect, useState } from "react"

import { Message } from "../../../api"
import { Link, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import DataTable  from "react-data-table-component";

const dateSort = (rowA, rowB) =>{ 
  return new Date(rowA.date_sent).getTime() - new Date(rowB.date_sent).getTime();
}

const customStyles = {
	headRow: {
		style: {
			border: 'none',
      backgroundColor: "rgba(3, 105, 161, 1)",
		},
	},
  rows: {
		highlightOnHoverStyle: {
			backgroundColor: 'rgb(230, 244, 244)',
			borderBottomColor: '#FFFFFF',
			outline: '1px solid #FFFFFF',
		}
	},
	headCells: {
		style: {
			color: '#fff',
			fontSize: '0.8rem',
		},
	},
	pagination: {
		style: {
			border: 'none',
		},
	}

};

const ContactMessages = () => {
  const navigate = useNavigate();
  const [submissions, setSubmissions] = useState([]);
  
  const columns = [
    { name: 'Msg ID', selector: row => row.msg_id},
    { name: 'Name', selector: 'user_name', sortable: true },
    { name: 'Level', selector: row => row.level.charAt(0).toUpperCase() + row.level.slice(1), sortable: true },
    { name: 'User Type', selector: row => row.user_type.charAt(0).toUpperCase() + row.user_type.slice(1) + " user", sortable: true },
    { name: 'Submission Date', selector: row => new Date(row.date_sent).toDateString(), sortable: true, sortFunction: dateSort },
    { name: 'Status', selector: row => row.replied ? "Replied" : "Pending", sortable: true, sortFunction: dateSort },
    { name: 'Action', selector: (row) => <Link to={`/contact-messages/${row.msg_id}`}>View Details &gt;&gt;</Link> },
  ];

  useEffect(() => {
    Message.getAll()
    .then((res) => {
      setSubmissions(res.messages);
    })
    .catch((err) => {
      const resp = err.response?.data || {message: "Server error"};
      toast.error(resp.message, {autoClose: 2000});
      navigate("/");
    })

  }, [])

  return (
    <div className="feedback-admin">
      <u>
        <Link to={"/feedbacks"}>&lt;&lt; Back to feedbacks</Link>
      </u>
      <div className="head">
        <h2 className="h2 tracking-tighter">Contact Messages</h2>
        {/* <Link
        to={"/feedbacks/"+ params.id +"/summary"}
          className="create-new"
        >
          <ChartBarIcon className="h-5" />
          View summary
        </Link> */}
      </div>

      <div className="flex flex-col gap-y-4">

        <span className="text-lg text-gray-700">Total Submissions: {submissions.length}</span>

        
      <div className="table_container">
        <DataTable
        data={
          submissions
        }
        columns={columns}
        customStyles={customStyles}
        pagination
        paginationComponentOptions={
          {selectAllRowsItem: true, selectAllRowsItemText: "Show all"}
        }
        />
      </div>

        {submissions.length === 0 && <small>No results</small>}
      </div>
    </div>
  )
}

export default ContactMessages
