import { useState, useEffect } from "react";
import { toast } from "react-toastify";

// import Loader from "../../../../components/Loader"
import dummyPhoto from "../../../assets/image/default.png";
import { Feedback, User } from "../../../api";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import UserSubmissions from "./Submissions";

const UpdateProfile = ({user}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [submissionsLoaded, setSubmissionsLoaded] = useState(false);
  const [submissions, setSubmissions] = useState([]);
  const params = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault()
  }

  const loadSubmissions = () => {
    setIsLoading(true);
    Feedback.getUserSubmissions({user_id: params.id})
    .then((res) => {
      setIsLoading(false);
      setSubmissionsLoaded(true);
      setSubmissions(res.submissions);
    })
  }

  return (
    <form
      className="relative bg-white px-5 py-5 flex flex-col gap-y-5 rounded-lg"
      onSubmit={handleSubmit}
    >
      {isLoading && (
        <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-50">
          <Loader type="large" dark />
        </div>
      )}

      <div className="relative h-28 w-28 md:h-40 md:w-40">
        <img
          className="h-full w-full object-cover rounded-full"
          src={dummyPhoto}
          alt=""
        />
      </div>
      <h4 className="h4 text-left">{user.fname + " " + user.lname}</h4>
      <hr />
      <h4 className="h4 m-0">Account Information</h4>
      <table className="table border">
        <tr className="bg-sky-700 text-white py-5">
          <th className="p-2">Number of submissions</th>
          <th>Last Submission</th>
          <th>Last Login</th>
          <th>Date registered</th>
          <th></th>
        </tr>
        <tr className="card">
          <td className="p-2">{user.submissions && user.submissions.toString().padStart(2, '0')}</td>
          <td>{user.submissions > 0 ? new Date(user.lastSubmission).toDateString() : "No submission yet"}</td>
          <td>---</td>
          <td>{user.registered && new Date(user.registered).toDateString()}</td>
          <td></td>
        </tr>
      </table>

      {/* <div className="flex flex-row flex-wrap gap-3">
        <div className="flex flex-col gap-y-1 flex-grow items-start">
          <label className="text-gray-500 text-sm" htmlFor="studentemail">
            No. of Submissions
          </label>
          <p>{user.submissions && user.submissions.toString().padStart(2, '0')}</p>
        </div>
        <div className="flex flex-col gap-y-1 flex-grow items-start">
          <label className="text-gray-500 text-sm" htmlFor="studentemail">
            Last Submission
          </label>
          <p>{user.submissions > 0 ? new Date(user.lastSubmission).toDateString() : "No submission yet"}</p>
        </div>
        <div className="flex flex-col gap-y-1 flex-grow items-start">
          <label className="text-gray-500 text-sm" htmlFor="studentemail">
            Last Login
          </label>
          <p>---</p>
        </div>
        <div className="flex flex-col gap-y-1 flex-grow items-start">
          <label className="text-gray-500 text-sm" htmlFor="studentemail">
            Date Registered
          </label>
          <p>{user.registered && new Date(user.registered).toDateString()}</p>
        </div>
      </div> */}

      <hr />
      <h4 className="h4 m-0">Personal Information</h4>
      
      <table className="table">
        <tr className="bg-sky-700 text-white py-5">
          <th className="p-2">Student Email</th>
          <th>Personal Email</th>
          <th>Phone</th>
        </tr>
        <tr className="card">
          <td className="p-2">{user.studentEmail || "Not provided"}</td>
          <td>{user.personalEmail || "Not provided"}</td>
          <td>{user.phone || "Not provided"}</td>
        </tr>
        <tr className="card">
          <td className="p-2"></td>
        </tr>
        <tr className="bg-sky-700 text-white py-5">
          <th className="p-2">Institution</th>
          <th>Department</th>
          <th>Level</th>
        </tr>
        <tr className="card">
          <td className="p-2">{user.institution || "Not provided"}</td>
          <td>{user.department || "Not provided"}</td>
          <td>{user.level || "Not provided"}</td>
        </tr>
      </table>

      <hr />

      {
        (user.submissions > 0 && !submissionsLoaded) &&
      <div className="mb-0">
      <h5 className="h5 mb-0">User Submissions</h5>
        <button className="underline" onClick={loadSubmissions}>Display submissions &gt;&gt;</button>
      </div>
      }
      {
        (submissionsLoaded) &&
      <div className="mb-0">
        <button className="underline" onClick={() => setSubmissionsLoaded(false)}>&lt;&lt; Hide submissions</button>
      </div>
      }

      {
        submissionsLoaded &&
          <UserSubmissions submissions={submissions} />
      }

      <button
        // disabled={isLoading ? true : false}
        type="submit"
        className="flex py-2 px-3 items-center justify-center bg-sky-600 text-white font-semibold rounded-lg disabled:bg-opacity-70 flex-grow"
      >
        Send Message
      </button>

      <button
        // disabled={isLoading ? true : false}
        type="button"
        className="flex py-2 px-3 items-center justify-center bg-red-600 text-white font-semibold rounded-lg disabled:bg-opacity-70 flex-grow"
      >
        Deactivate account
      </button>
    </form>
  )
}

export default UpdateProfile
