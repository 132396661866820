import { FolderIcon } from "@heroicons/react/outline"

const InstitutionPageHeader = ({institution}) => {

  return (
    <div className="dashboardheader">
      <div className="head">
        <h4 className="h4">{institution?.name}</h4>
        <small>
          { institution.institution_type && institution.institution_type.charAt(0).toUpperCase() + institution.institution_type.slice(1) }
        </small>
      </div>

      <div className="stats">
        <div className="stat">
          <FolderIcon />

          <div>
            <h5 className="h5">{institution?.num_of_feedbacks || 0}</h5>
            <small>Submissions</small>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InstitutionPageHeader
