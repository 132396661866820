import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/solid"
import React, { Fragment, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { Feedback } from "../../../api"

const CreateNewModal = ({ isOpen, closeModal }) => {
  const navigate = useNavigate()
  const [form, setForm] = useState({type: "", description: "", submittable_by: "everyone"});
  const [loading, setLoading] = useState(false);

  const inputChange = e => {
    setForm({...form, [e.target.name]: e.target.value });
  }

  const submitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    Feedback.addFeedbackType(form).then(res => {
      setLoading(false);
      if (res.type && res.type !== "error") {
        toast.success("Feedback created successfully!", {autoClose: 2000})
        navigate("edit")
      }
    }).catch((err) => {
      setLoading(false);
      const resp = err.response?.data || {message: "Server error"};
      toast.error(resp.message, {autoClose: 2000});
    })
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Create new feedback
                </Dialog.Title>
                <div className="mt-2">
                  <input
                    className="py-2 px-4 ring-1 ring-gray-200 w-full rounded-lg"
                    placeholder="Enter feedback title..."
                    name="title"
                    value={form.title}
                    onChange={inputChange}
                  />
                </div>
                <div className="mt-3">
                  <textarea 
                  className="py-2 px-4 ring-1 ring-gray-200 w-full rounded-lg"
                  id="" 
                  cols="30" rows="5"
                  placeholder="Enter Feedback description.."
                  name="description"
                  value={form.description}
                  onChange={inputChange}
                  ></textarea>
                </div>
                <div className="mt-3">
                  <label htmlFor="submittable">Who can submit a response?</label>
                  <select 
                  className="py-2 px-4 ring-1 ring-gray-200 w-full rounded-lg"
                  id="submittable"
                  name="submittable_by"
                  value={form.submittable_by}
                  onChange={inputChange}
                  >
                    <option value="everyone">Everyone</option>
                    <option value="reg_users">Only All Registered Users</option>
                    <option value="undergraduates">Only Undergraduate Registered Users</option>
                    <option value="postgraduates">Only Postgraduate Registered Users</option>
                  </select>
                </div>

                <div className="mt-4 flex flex-wrap gap-2 items-center justify-between">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-black px-4 py-2 text-sm font-medium text-gray-50 hover:bg-gray-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                    onClick={submitForm}
                  >
                    {loading ? "Creating..." : "Create"}
                  </button>

                  <button
                    type="button"
                    className="inline-flex items-center justify-center gap-2 rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-red-50 hover:bg-red-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                    onClick={() => {
                      closeModal()
                    }}
                  >
                    <XIcon className="h-5" />
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default CreateNewModal
