import { EyeIcon, PencilIcon, TrashIcon } from "@heroicons/react/outline"
import { DotsVerticalIcon } from "@heroicons/react/solid"
import React, { useState, useRef, useEffect } from "react"
import { Link } from "react-router-dom"

import Transition from "../../../../utils/Transition"
import DeleteFeedback from "./DeleteFeedback"

function CardMenu({ name, id, deleteSchool }) {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const trigger = useRef(null)
  const dropdown = useRef(null)

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return
      setDropdownOpen(false)
    }
    document.addEventListener("click", clickHandler)
    return () => document.removeEventListener("click", clickHandler)
  })

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return
      setDropdownOpen(false)
    }
    document.addEventListener("keydown", keyHandler)
    return () => document.removeEventListener("keydown", keyHandler)
  })

  function closeDeleteModal() {
    setIsOpen(false)
  }

  function openDeleteModal() {
    setIsOpen(true)
  }

  return (
    <div className="relative inline-flex">
      <button
        ref={trigger}
        className="inline-flex z-10 justify-center items-center group"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <DotsVerticalIcon className="h-5" />
      </button>

      <Transition
        className="origin-top-right z-20 absolute top-full right-0 min-w-44 bg-white border border-sky-200 rounded shadow-lg overflow-hidden mt-1"
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <div>
            <Link
              to={"/institutions/" + id}
              className="flex gap-2 items-center justify-between px-2 py-1 bg-sky-50 border-b border-sky-400 text-sky-700 font-bold"
            >
              View <EyeIcon className="h-4" />
            </Link>

            <Link
              to={"/institutions/" + id + "/edit"}
              className="flex gap-2 items-center justify-between bg-sky-50 border-b border-sky-400 px-2 py-1 text-sky-700 font-bold"
            >
              Edit <PencilIcon className="h-4" />
            </Link>

            <button
              onClick={() => openDeleteModal()}
              className="flex w-full gap-2 items-center justify-between bg-red-50 px-2 py-1 text-red-700 font-bold"
            >
              Delete <TrashIcon className="h-4" />
            </button>
          </div>
        </div>
      </Transition>

      <DeleteFeedback
        name={name}
        isOpen={isOpen}
        closeModal={closeDeleteModal}
        school_id={id}
        deleteSchool={deleteSchool}
      />
    </div>
  )
}

export default CardMenu
