import ReactApexChart from "react-apexcharts"

const config = {
  series: [
    {
      name: "Users",
      data: [400, 730, 448, 470, 540, 580],
    },
  ],
  options: {
    stroke: {
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        distributed: true,
        startingShape: "flat",
        endingShape: "rounded",
        dataLabels: {
          position: "bottom",
        },
      },
    },
    xaxis: {
      categories: ["JAN", "FEB", "MARCH", "APRIL", "MAY", "JUNE"],
      labels: {
        style: {
          fontFamily: "Red Hat Display",
        },
      },
      group: {
        style: {
          fontFamily: "Red Hat Display",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontFamily: "Red Hat Display",
        },
      },
    },
    dataLabels: {
      style: {
        fontFamily: "Red Hat Display",
      },
    },
    tooltip: {
      style: {
        fontFamily: "Red Hat Display",
      },
    },
  },
}

const DashboardChart = ({ type, data }) => {
  
  return (
    <div className="dashboardchart">
      <ReactApexChart
        options={{
          ...config.options,
          xaxis: {...config.options.xaxis, categories: data.map(d => d.title)}
        }}
        series={[
          {name: "Users", data: data.map(d => d.users)}
        ]}
        key={type}
        type={type}
      />
    </div>
  )
}

export default DashboardChart
