
import { useEffect, useState } from "react"
import { PlusCircleIcon } from "@heroicons/react/solid"
import { Institution } from "../../../../api"
import { toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"
import FacultyCard from "./FacultyCard"
import AddFacultyModal from "./AddFacultiesModal"
import AddDepartmentModal from "./AddDepartmentsModal"

const FacultiesData = ({school}) => {
    const navigate = useNavigate();
    const params = useParams();
    const [isOpen, setIsOpen] = useState(false);
    const [isDeptOpen, setIsDeptOpen] = useState(false);
    const [faculties, setFaculties] = useState([]);
    const [deptLoading, setDeptLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [currentFaculty, setCurrentFaculty] = useState("");
    const [uniFaculties, setUniFaculties] = useState("");
    const [facultyDepts, setFacultyDepts] = useState("");
    const [deptFaculty, setDeptFaculty] = useState({name: "", id: ""});

    function closeModal() {
        setIsOpen(false)
    }
    function closeDeptModal() {
        setIsDeptOpen(false)
    }

    useEffect(() =>{
        Institution.getSchoolFaculties(params.id)
        .then((res) => {
            setFaculties(res.faculties);
        })
        .catch((err) => {
            const error = err?.response.data || {message: "An error occured"};
            toast.error(error.message, {autoClose: 2000});
            navigate("/institutions");
        })
    }, [navigate, params.id])

    const getFacultyDepts = (id) => {
        const faculty = faculties.find(f => f.id === id);
        setCurrentFaculty(id);
        if (!faculty.departments) {
            setDeptLoading(true);
            Institution.getFacultyDepartments(id)
            .then((res) => {
                setDeptLoading(false);
                const facultiesClone = [...faculties];
                const facultyIndex = faculties.findIndex(f => f.id === id);
                facultiesClone[facultyIndex].departments = res.departments;
                setFaculties(facultiesClone);
            })
            .catch((err) => {
                setDeptLoading(false);
                const error = err?.response.data || {message: "An error occured"};
                toast.error(error.message, {autoClose: 2000});
                navigate("/institutions");
            })
        }
    }
    
    const deleteFaculty = () => {
        toast.error("You cannot delete this faculty.", {autoClose: 2000});
    }

    const submitForm = () => {
        if (uniFaculties.trim().length < 1) {
            return toast.warning("Please input faculties", {autoClose: 2000});
        }
        setFormLoading(true);
        Institution.addFaculties(school.id, { faculties: uniFaculties.replace(/, /g, ",").split(",") })
        .then(() => {
            setFormLoading(false);
          toast.success("Faculties added successfully!", {autoClose: 2000});
          navigate("/");
        })
        .catch(err => {
            setFormLoading(false);
            const error = err.response?.data || {message: "Error adding faculties. Go back a step and retry."};
            toast.error(error.message, {autoClose: 2000});
        })
    }

    const uniFacultyChange = (val) => {
        setUniFaculties(val);
    }

    const facultyDeptsChange = (val) => {
        setFacultyDepts(val);
    }

    const addDepartments = (facultyId, name) => {
        setDeptFaculty({id: facultyId, name});
        setIsDeptOpen(true);
    }

    const submitDeparmentForm = () => {
        console.log(school.id, deptFaculty.id, facultyDepts);
        const departments = facultyDepts.replace(/ , /g, ",").replace(/, /g, ",").replace(/ ,/g, ",").split(",");
        Institution.addDepartments(school.id, deptFaculty.id, {departments})
        .then((res) => {
            setFormLoading(false);
            toast.success("Departments added successfully!", {autoClose: 2000});
            navigate("/institutions");
        })
        .catch((err) => {
            setFormLoading(false);
            const error = err.response?.data || {message: "Error adding departments. Go back a step and retry."};
            toast.error(error.message, {autoClose: 2000});
        })
    }

  return (
    <div className="submissions">
      <button
      className="px-4 py-2 bg-amber-700 text-white font-semibold flex items-center gap-2 shadow-md rounded-lg"
      onClick={() => setIsOpen(true)}
      >
         <PlusCircleIcon className="h-5" /> New Faculty
      </button>

      <div className="feedbackdetails">
        <h2 className="h4">Faculties</h2>
        <p>
        </p>
        {
            faculties &&
            faculties.map((faculty, i) => {
            return (
                <FacultyCard 
                key={i}
                faculty={faculty}
                i={i}
                deptLoading={deptLoading}
                currentFaculty={currentFaculty}
                getFacultyDepts={getFacultyDepts}
                deleteFaculty={deleteFaculty}
                school={params.id}
                addDepartments={() => addDepartments(faculty.id, faculty.name)}
                />
            )
            })
        }
        </div>

      <AddFacultyModal
        isOpen={isOpen}
        closeModal={closeModal}
        school={{name: school.name, id: school.name}}
        form={{submitForm, faculties: uniFaculties, facultyChange: uniFacultyChange, loading: formLoading}}
      />

      <AddDepartmentModal
        isOpen={isDeptOpen}
        closeModal={closeDeptModal}
        school={{name: school.name, id: school.name, faculty: deptFaculty}}
        form={{submitForm: submitDeparmentForm, departments: facultyDepts, deptChange: facultyDeptsChange, loading: formLoading}}
      />
    </div>
  )
}

export default FacultiesData
