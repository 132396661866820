
import Loader from "../../components/Loader";
import Profile from "./components/Profile"
import { useNavigate, useParams } from "react-router-dom";
import { Institution, User } from "../../api";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const UserProfile = () => {
  const navigate = useNavigate();
  const [pageLoading, setPageLoading] = useState(true);
  const [user, setUser] = useState({});
  const params = useParams();
  const [schools, setSchools] = useState([]);
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    Institution.getAll()
    .then(res => {
      setSchools(res.schools);
    })

    Institution.getAllDepartments()
    .then(res => {
      setDepartments(res.departments);
    })

    User.getProfile({userID: params.id}).then(res => {
      setPageLoading(false);
      if (res.type === "error") {
        toast.error(res.message);
        return navigate("/");
      }
      setUser(res.profile);
    }).catch(err => {
      setPageLoading(false);
      console.log(err);
      const error = err.response?.data || {message: "Network error"};
      return toast.error(error.message);
    })
  }, [navigate, params.id])

  return (
    <div className="w-full p-5">
      <button onClick={() => navigate(-1)}><u>&lt;&lt;Go Back</u></button>
      <h2 className="h2 border-b py-5 border-gray-200">
        User Profile
      </h2>

      {pageLoading && (
        <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-50">
          <Loader type="large" dark />
        </div>
      )}
      <div className="w-full mt-1">
        <Profile 
        user={
          { 
            ...user, 
            institution: schools.find(sch => sch.id === user.institution)?.name,
            department: departments.find(dept => dept.id === user.department)?.name
          }
        } 
        />
      </div>
    </div>
  )
}

export default UserProfile;
