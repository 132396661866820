import { DotsHorizontalIcon } from "@heroicons/react/solid"

const imagesUrl = {
  institution: "institution.svg",
  course: "course.svg",
  department: "department.svg",
  faculty: "faculty.svg",
  user: "default.png"
}

const AdminProfileCard = ({ superadmin, title, time }) => {
  return (
    <div className="card cursor-pointer hover:opacity-80 duration-200">
      <div className="image">
        <img
          className="h-10"
          src={`/images/${imagesUrl["user"]}`}
          alt="Admin"
        />
      </div>

      <div>
        <h4 className="h4 text-gray-700 font-semibold">
          {title.length > 28 ? `${title.substring(0, 25)}...` : title}
        </h4>

        {/* <DotsHorizontalIcon className="h-5 cursor-pointer text-gray-500" /> */}
      </div>

      <div className="metadata">
        <span className="type">{superadmin ? "Superior Admin" : "Administrator"}</span>

        <span className="date">{new Date(time).toDateString().slice(0, 10) } | {new Date(time).toLocaleTimeString()}</span>
      </div>
    </div>
  )
}

export default AdminProfileCard
