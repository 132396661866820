
import { useNavigate } from "react-router-dom";
import { Feedback } from "../../../api";
import { useEffect } from "react";
const UsersSummary = () => {
  const navigate = useNavigate();

  useEffect(() => {

    Feedback.getSubmissionsSummary({feedback_id: ""})

  }, [])

  return (
    <div className="w-full p-5">
      <button onClick={() => navigate(-1)}><u>&lt;&lt;Go Back</u></button>
      <h2 className="h2 border-b py-5 border-gray-200"> Users Data Summary </h2>

      <div className="w-full mt-1">
        {/* <Profile
          user={
            {
              ...user, 
              institution: schools.find(sch => sch.id === user.institution)?.name,
              department: departments.find(dept => dept.id === user.department)?.name
            }
          }
        /> */}
      </div>
    </div>
  )
}

export default UsersSummary;
