import { useEffect, useState } from "react"
import { Institution } from "../../../../api"
import dummyPhoto from "../../../../assets/image/default.png";
import { Link } from "react-router-dom";

const FeedbackDetails = ({message}) => {

  return (
    <div className="feedbackdetails">
      <h2 className="h4">#{message.msg_id}</h2>
      <img
        className="md:h-10 md:w-10 object-cover rounded-full"
        src={dummyPhoto}
        alt=""
        style={{marginBottom: "0px", padding: "0px"}}
      />
      <p
        style={{marginTop: "0px", padding: "0px"}}> 
        By: &nbsp; {message.user_name} ({ message.user_type === "anonymous" ? "Anonymous" : "Registered"})
        
        <br />
        Submitted: <b>{message.date_sent && new Date(message.date_sent).toDateString() + ", " + new Date(message.date_sent).toLocaleTimeString()}</b>
      </p>

      
      <div className="feedbackblock">
        <FeedbackHead text={"Message Subject"} />

        <div className="detail">
          {
            message.subject
          }
        </div>
      </div>
      
      <div className="feedbackblock">
        <FeedbackHead text={"Message Body"} />
        <div className="detail">
          {
            message.message_body
          }
        </div>
      </div>

    </div>
  )
}

const FeedbackHead = ({ text, required = false }) => (
  <div>
    <span className="text-lg tracking-tight font-semibold text-black">
      {text}
    </span>
    {required && <span className="text-rose-500 text-lg">*</span>}
  </div>
)

export default FeedbackDetails
