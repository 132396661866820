import { Link } from "react-router-dom"

const NavbarLink = ({ title, to }) => {
  console.log(title, to)
  return (
    <span><Link to={to}>{title}</Link></span>
  )
}

export default NavbarLink
