import { EyeIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom"

const UserProfileButton = ({id}) => {
    return (
        <Link
        to={"/users/" + id}
          className="inline-flex items-center gap-2 bg-sky-100 text-sky-700 py-2 px-4 rounded mr-5 font-bold"
        >
          <EyeIcon className="h-3" />
          <small className="text-sky-800">View profile</small>
        </Link>
    )
}

export default UserProfileButton;