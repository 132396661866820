import DepartmentCard from "./DepartmentCard";
import { ChevronDownIcon, PlusCircleIcon } from "@heroicons/react/solid"

const FacultyCard = ({faculty, i, deleteFaculty, getFacultyDepts, deptLoading, currentFaculty, addDepartments, school}) => {
    return (
        <div className="feedbackblock" key={i}>
            <FeedbackHead text={faculty.name} index={i+1} deleteFaculty={deleteFaculty} />

            <button className=" rounded font-bold text-sm mr-4 flex inline-flex align-center justify-between" onClick={() => getFacultyDepts(faculty.id)}> 
            <ChevronDownIcon width={"15px"} className="inline mx-1" /> Departments
            </button>

            <button 
            className="bg-indigo rounded text-white text-sm p-1 px-2 flex inline-flex align-center justify-between"
            onClick={addDepartments}
            > 
            <PlusCircleIcon width={"15px"} className="inline mx-1" /> Departments
            </button>
            <div className="detail">
                {
                    (currentFaculty === faculty.id && deptLoading) && "Loading Departments..."
                }
                <ul>
                    {
                        faculty.departments &&
                        faculty.departments.map((dept, i) => {
                            return (
                                <DepartmentCard dept={dept} key={i} school={school} />
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
}


const FeedbackHead = ({ text, index, deleteFaculty }) => (
    <div className="flex justify-between">
      <span className="text-lg tracking-tight font-semibold text-black">
        {index}. {text}
      </span>
      <div>

        <button className="rounded text-whites text-sm p-1 px-2 m-2" style={{backgroundColor: "skyblue"}}>Edit</button>
        <button className="rounded text-white text-sm p-1 px-2 m-2" style={{backgroundColor: "red"}} onClick={deleteFaculty}>Delete</button>
      </div>
    </div>
  )

export default FacultyCard;