import React, { useState } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import AdminHeader from "../components/AdminHeader"
import AdminSidebar from "../components/AdminSidebar"
import Cookie from "js-cookie";
import { useEffect } from "react";
import { Admin } from "../api";
import { toast } from "react-toastify";

const AdminLayout = () => {
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const adminToken = Cookie.get("akada__admin");
  const [admin, setAdmin] = useState({});
  
  useEffect(() => {
    if (!adminToken) {
      return navigate("/login");
    }
    
    Admin.profile().then(res => {
      setAdmin(res.admin);
    }).catch((err) => {
      const resp = err.response?.data || {message: "Server error"};
      toast.error(resp.message, {autoClose: 2000});
      Cookie.remove("akada__admin");
      navigate("/login");
    })
  }, []);


  return (
    <div className="flex h-screen overflow-hidden">
      <AdminSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <AdminHeader
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          admin={admin}
        />
        
        <main>
          <Outlet />
        </main>
      </div>
    </div>
  )
}

export default AdminLayout
