import ReactApexChart from "react-apexcharts"


const options = {
    series: [44, 55, 13, 33],
    labels: ['Apple', 'Mango', 'Orange', 'Watermelon']
}

const SummaryChart = ({response, i, schools, departments}) => {
    return (
        <div className="feedbackblock my-5">
            <h4 className="h4">{i}. {response.question_text}</h4>

          <div className="detail">
              <ReactApexChart 
              type="pie" 
              options={{
                  series: response.answers.map(ans =>  ans.responses),
                  labels: response.response_format === "institution" ? response.answers.map(ans => schools.find(sch => sch.id === parseInt(ans.answer))?.name ) : response.response_format === "department" ? response.answers.map(ans => departments.find(dept => dept.id === parseInt(ans.answer))?.name)  : response.answers.map(ans => ans.answer),
                  plotOptions: {
                      pie: {
                          customScale: 0.8
                      }
                  }
              }} 
              series={response.answers.map(ans => ans.responses)} 
              />
          </div>
        </div>
    )
}


export default SummaryChart;