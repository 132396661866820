import { MenuAlt2Icon, XIcon } from "@heroicons/react/outline"
import { useEffect } from "react"
import { useState } from "react"
import { Link } from "react-router-dom"
import Logo from "../../assets/Logo"
import NavbarLink from "./NavbarLink"

const Navbar = () => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", function () {
      if (this.scrollY >= 60) {
        document.querySelector(".home-menu-btn").classList.add("fixed-btn")
      } else {
        document.querySelector(".home-menu-btn").classList.remove("fixed-btn")
      }
    })
  }, [])

  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      if (
        e.target.classList.contains("items-container") ||
        e.target.classList.contains("auth-action") ||
        e.target.classList.contains("items") ||
        e.target.classList.contains("home-menu-btn") ||
        e.target.classList.contains("fixed-btn") ||
        e.target.classList.contains("nav-toggle") ||
        e.target.classList.contains("nav-toggle-btn") 
      )
        return

      setOpen(false)
    })
  }, [])

  return (
    <nav className="home-navbar">
      <Link className="nav-logo" to="/">
        <Logo className="h-7 text-indigo logo" />
      </Link>

      <div className={`${open ? " right-0" : "-right-full"} items-container`}>
        <span className="items">
          <NavbarLink title="Home" to="/" />
          <NavbarLink title="About" to="/about" />
          <NavbarLink title="Request Data" to="/request-data" />
        </span>

        <span className="auth-action">
          <Link to="/login">
            <button>Log In</button>
          </Link>

          <Link
            to="/signup"
            className="bg-black text-white px-5 py-2 rounded-full"
          >
            <button>Sign Up</button>
          </Link>
        </span>

        <XIcon
          onClick={() => setOpen(false)}
          className="h-7 cursor-pointer nav-close"
        />
      </div>

      <button
        onClick={() => setOpen(true)}
        className="bg-black p-4 -mb-16 nav-toggle cursor-pointer home-menu-btn"
      >
        <MenuAlt2Icon className="text-white h-8 nav-toggle-btn" />
      </button>
    </nav>
  )
}

export default Navbar
