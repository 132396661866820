import { useState } from "react"
import { Institution } from "../../../../api"
import DropListAutocomplete from "../../../../components/DropListAutocomplete"
import InputText from "../../../../components/FeedbackElements/InputText"
import MultiChoice from "../../../../components/FeedbackElements/MultiChoice"
import RadioFeedback from "../../../../components/FeedbackElements/RadioFeedback"
import SingleChoice from "../../../../components/FeedbackElements/SingleChoice"
import TextArea from "../../../../components/FeedbackElements/TextArea"
import { useEffect } from "react"

/* const elements = {
  shorttext: {
    type: "shorttext",
    text: "Short Answer",
    config: ["placeholder", "label", "maxLength"],
  },
  longtext: {
    type: "longtext",
    text: "Paragraph",
    config: ["placeholder", "label", "maxLength"],
  },
  singlechoice: {
    type: "singlechoice",
    text: "Single Choice",
    config: ["label", "options"],
  },
  multichoice: {
    type: "multichoice",
    text: "Multiple Choices",
    config: ["label", "options"],
  },
  dropdown: {
    type: "dropdown",
    text: "Dropdown",
    config: ["label", "options"],
  },
  reaction: {
    type: "reaction",
    text: "Reaction",
    config: ["label", "options"],
  },
} */
const Preview = ({ desc, config }) => {
  const [institutions, setInstitutions] = useState([]);
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    Institution.getAll().then(res => {
      setInstitutions(res.schools);
      getDepartments(res.schools[0].id);
    }).catch(err => {
      console.log(err);
    })
  }, [])

  const getDepartments = (school) => {
    const schID = institutions.find(i => i.name === school).id;
    Institution.getDepartments({school_id: schID}).then(res => {
      setDepartments(res.departments);
    })
  }
  
  return (
    <div className="w-full md:w-3/4 p-5 pb-8 mx-auto bg-white border-b-4 border-sky-800">
      <div className="space-y-2 pb-4 mb-4 border-b border-gray-200">
        {desc.title && <h2 className="h2 tracking-tighter">{desc.title}</h2>}
        {desc.description && (
          <p className="text-base text-gray-600">{desc.description}</p>
        )}
      </div>

      <div className="questions space-y-4">
        {config.map((c, index) => (
          <div className="flex items-start gap-4" key={c.id}>
            <span className="h-6 w-6 bg-gray-700 text-white text-sm font-semibold flex items-center justify-center rounded-full">
              {index + 1}
            </span>

            {c.type === "singlechoice" && <SingleChoice config={c} />}
            {c.type === "shorttext" && <InputText config={c} />}
            {c.type === "longtext" && <TextArea config={c} />}
            {c.type === "multichoice" && <MultiChoice config={c} />}
            {c.type === "dropdown" && (
              <div className="flex flex-col gap-y-2">
                {c.heading && <h5 className="h5 font-semibold">{c.heading}</h5>}
                <label className="text-sm text-gray-600">{c.label}</label>
                <DropListAutocomplete data={c.options} />
              </div>
            )}
            {c.type === "reaction" && (
              <div className="flex flex-col gap-y-2">
                {config.heading && <h5 className="h5 font-semibold">{config.heading}</h5>}
                <label className="text-sm text-gray-600">{c.label}</label>
                <RadioFeedback data={c.options} />
              </div>
            )}

            {c.type === "institution" && (
              <div className="flex flex-col gap-y-2 w-full">
                {c.heading && <h5 className="h5 font-semibold">{c.heading}</h5>}
                <label className="text-sm text-gray-600">{c.label}</label>
                <DropListAutocomplete data={institutions.map(i => i.name)} onChange={(school) => getDepartments(school)} />
              </div>
            )}

            {c.type === "department" && (
              <div className="flex flex-col gap-y-2 w-full">
                {c.heading && <h5 className="h5 font-semibold">{c.heading}</h5>}
                <label className="text-sm text-gray-600">{c.label}</label>
                <DropListAutocomplete data={departments.map(dept => dept.name)} />
              </div>
            )}

          </div>
        ))}
      </div>
    </div>
  )
}

export default Preview
