import { TrendingUpIcon } from "@heroicons/react/solid"
import { useNavigate } from "react-router-dom";
import { UserIcon } from "@heroicons/react/outline";

const DashboardHeader = ({admin, data}) => {

  return (
    <div className="dashboardheader">
      <div className="head">
        <h4 className="h4">Welcome, {admin.fname}!</h4>
        <small> { admin.superadmin ? "Superior " : "" } Administrator</small>
      </div>

      <div className="stats">
        <div className="stat">
          <UserIcon />

          <div>
            <h5 className="h5">{data?.users?.toString().padStart(3, '0')}</h5>
            <small>Total registered users.</small>
          </div>
        </div>

        <div className="stat">
          <TrendingUpIcon />

          <div>
            <h5 className="h5">{data?.submissions?.toString().padStart(3, '0')}</h5>
            <small>Feedbacks submitted.</small>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardHeader
