import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/solid"
import React, { Fragment, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { Feedback, Institution } from "../../../api"

const CreateNewModal = ({ isOpen, closeModal }) => {
  const navigate = useNavigate()
  const [form, setForm] = useState({name: "", type: "university", level: "federal", state: ""});
  const [loading, setLoading] = useState(false);

  const inputChange = e => {
    setForm({...form, [e.target.name]: e.target.value });
  }

  const submitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    const dataForm = {name: form.name, institution_type: form.type, institution_level: form.level, state: form.state};

    Institution.add(dataForm).then(res => {
      setLoading(false);
      if (res.type !== "error") {
        toast.success("Institution added successfully!", {autoClose: 2000})
        navigate("/institutions/" + res.school_id);
      }
    }).catch((err) => {
      setLoading(false);
      const resp = err.response?.data || {message: "Server error"};
      toast.error(resp.message, {autoClose: 2000});
    })
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Add new institution
                </Dialog.Title>
                <div className="mt-2">
                  <input
                    className="py-2 px-4 ring-1 ring-gray-200 w-full rounded-lg"
                    placeholder="Enter intitution name..."
                    name="name"
                    value={form.name}
                    onChange={inputChange}
                  />
                </div>
                <div className="mt-3">
                  <label htmlFor="type">Institution Type</label>
                  <select 
                  className="py-2 px-4 ring-1 ring-gray-200 w-full rounded-lg"
                  id="type"
                  name="type"
                  value={form.type}
                  onChange={inputChange}
                  >
                    <option value="univeristy">Univeristy</option>
                    <option value="polytechnic">Polytechnic</option>
                    <option value="college of education">College of Education</option>
                  </select>
                </div>
                <div className="mt-3">
                  <label htmlFor="level">Institution Level</label>
                  <select 
                  className="py-2 px-4 ring-1 ring-gray-200 w-full rounded-lg"
                  id="level"
                  name="level"
                  value={form.level}
                  onChange={inputChange}
                  >
                    <option value="federal">Federal</option>
                    <option value="state">State</option>
                    <option value="private">Private</option>
                  </select>
                </div>

                <div className="mt-3">
                  <label htmlFor="state">State Located</label>
                  <select 
                  className="py-2 px-4 ring-1 ring-gray-200 w-full rounded-lg"
                  id="state"
                  name="state"
                  value={form.state}
                  onChange={inputChange}
                  >
                    <option value="">- Select -</option>
                    <option value="Abuja FCT">Abuja FCT</option>
                    <option value="Abia">Abia</option>
                    <option value="Adamawa">Adamawa</option>
                    <option value="Akwa Ibom">Akwa Ibom</option>
                    <option value="Anambra">Anambra</option>
                    <option value="Bauchi">Bauchi</option>
                    <option value="Bayelsa">Bayelsa</option>
                    <option value="Benue">Benue</option>
                    <option value="Borno">Borno</option>
                    <option value="Cross River">Cross River</option>
                    <option value="Delta">Delta</option>
                    <option value="Ebonyi">Ebonyi</option>
                    <option value="Edo">Edo</option>
                    <option value="Ekiti">Ekiti</option>
                    <option value="Enugu">Enugu</option>
                    <option value="Gombe">Gombe</option>
                    <option value="Imo">Imo</option>
                    <option value="Jigawa">Jigawa</option>
                    <option value="Kaduna">Kaduna</option>
                    <option value="Kano">Kano</option>
                    <option value="Katsina">Katsina</option>
                    <option value="Kebbi">Kebbi</option>
                    <option value="Kogi">Kogi</option>
                    <option value="Kwara">Kwara</option>
                    <option value="Lagos">Lagos</option>
                    <option value="Nassarawa">Nassarawa</option>
                    <option value="Niger">Niger</option>
                    <option value="Ogun">Ogun</option>
                    <option value="Ondo">Ondo</option>
                    <option value="Osun">Osun</option>
                    <option value="Oyo">Oyo</option>
                    <option value="Plateau">Plateau</option>
                    <option value="Rivers">Rivers</option>
                    <option value="Sokoto">Sokoto</option>
                    <option value="Taraba">Taraba</option>
                    <option value="Yobe">Yobe</option>
                    <option value="Zamfara">Zamfara</option>
                  </select>
                </div>

                <div className="mt-4 flex flex-wrap gap-2 items-center justify-between">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-black px-4 py-2 text-sm font-medium text-gray-50 hover:bg-gray-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                    onClick={submitForm}
                  >
                    {loading ? "Creating..." : "Create"}
                  </button>

                  <button
                    type="button"
                    className="inline-flex items-center justify-center gap-2 rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-red-50 hover:bg-red-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                    onClick={() => {
                      closeModal()
                    }}
                  >
                    <XIcon className="h-5" />
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default CreateNewModal
