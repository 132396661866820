import { Link, useNavigate } from "react-router-dom"
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline"
import Helmet from 'react-helmet'
import { useState } from "react"
import Logo from "../../../assets/Logo"
import "../auth.scss"
import Loader from "../../../components/Loader";
import { Admin } from "../../../api";
import {toast} from "react-toastify";
import { useEffect } from "react"
import Cookies from "js-cookie"

const ChangePassword = () => {
  const navigate = useNavigate()
  const [show, setShow] = useState(false)
  const [show2, setShow2] = useState(false)
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({password: "", confirmPassword: ""});


  const inputChange = e => {
    setForm({...form, [e.target.name]: e.target.value });
  }
  const token = Cookies.get("akada__admin_unverified");

  useEffect(() => {
    if (!token) {
      return navigate("/login");
    }
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    Admin.changePassword({...form, token}).then(res => {
      setLoading(false)
      if (res.type === "success") {
        Cookies.remove("akada__admin_unverified");
        Cookies.set("akada__admin", token);
        toast.success("Password changed successfully!", {autoClose: 2000});
        return navigate("/dashboard");
      }
      toast.error(res.error, {autoClose: 2000});
      
    }).catch(err => {
      console.log(err);
      setLoading(false)
      const resp = err.response?.data || {message: "Server error"};
      toast.error(resp.message, {autoClose: 2000})
    })
  }

  return (
    <>
      <Helmet>
        <title>Change Password | Acadametrics Admin</title>
      </Helmet>
      <div className="auth">

        <form className="auth-form" onSubmit={handleSubmit}>
          <Link className="self-center block mb-8" to="/">
            <Logo className="h-10 logo" />
          </Link>

          <div className="auth-form__header">
            <h4 className="h4">Change Password</h4>
            <span className="text-gray-400">
              You need to set a new password to login
            </span>
          </div>

          <div className="auth-field forgot">
            <div>
              <input
                id="password"
                name="password"
                type={show ? "text" : "password"}
                placeholder="Choose a password"
                required
                onChange={inputChange}
                value={form.password}
              />

              <button className="btn" onClick={() => setShow(!show)} type="button">
                {show ? (
                  <EyeOffIcon className="h-4" type="button" />
                ) : (
                  <EyeIcon className="h-4" type="button" />
                )}
              </button>
            </div>
          </div>

          <div className="auth-field forgot">
            <div>
              <input
                id="confirmPassword"
                name="confirmPassword"
                type={show2 ? "text" : "password"}
                placeholder="Choose a password"
                required
                onChange={inputChange}
                value={form.confirmPassword}
              />

              <button className="btn" onClick={() => setShow2(!show2)} type="button">
                {show2 ? (
                  <EyeOffIcon className="h-4" />
                ) : (
                  <EyeIcon className="h-4" />
                )}
              </button>
            </div>
          </div>

          <button className="submit-button" type="submit">
            {loading ? <Loader /> : 'Login' }
          </button>

        </form>
      </div>
    </>
  )
}

export default ChangePassword
