
import { PencilAltIcon } from "@heroicons/react/outline"
import { TrashIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { Institution } from "../../../../api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const DepartmentCard = ({dept, school}) => {
    const navigate = useNavigate();

    const [editing, setEditing] = useState(false);
    const [newName, setNewName] = useState(dept.name);
    const [deptName, setDeptName] = useState(dept.name);

    const updateDept = () => {
        setEditing(false)
        Institution.updateDepartment(school, dept.id, {name: newName})
        .then((res) => {
            setDeptName(newName);
            toast.success(res.message);
        })
        .catch(err => {
            const resp = err.response?.data || {message: "Network error"};
            toast.error(resp.message, {autoClose: 2000});
            navigate("/institutions");
        })

    }

    const cancelUpdate = () => {
        setEditing(false)
    }

    return (
        <li key={dept.id} className="p-2 my-1 bg-cyan flex justify-between align-center hover:bg-darkcyan" >
            <div>
                {
                    editing ? 
                <div>
                    <input className="p-1" type="text" value={newName} onChange={(e) => setNewName(e.target.value)} />
                    <button onClick={updateDept} className="bg-indigo rounded text-white text-sm p-1 ml-3">Update</button>
                    <button onClick={cancelUpdate} className="bg-red rounded text-white text-sm p-1 ml-3"  style={{backgroundColor: "red"}}>Cancel</button>
                </div>
                :
                
                <p className="font-bold p2">{deptName}</p>
                }

            </div>

            <div>
                {
                    !editing &&
                    <button className="p-1 mx-1 inline-flex" onClick={() => setEditing(true)} >
                        <PencilAltIcon className="inline" width={"15px"}/> Edit
                    </button>
                }

                <button className="p-1 mx-1 inline-flex" style={{color: "red"}}>
                    <TrashIcon className="inline" width={"15px"} color="red" /> Delete
                </button>
            </div>
        </li>
    )
}

export default DepartmentCard;