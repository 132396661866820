import React, { useEffect, useState } from "react"

import InstitutionPageHeader from "./components/InstitutionPageHeader"
import "./FeedbackPage.scss"
import { Tab } from "@headlessui/react"
import Submissions from "./components/Submissions"
import { Institution } from "../../../api"
import { toast } from "react-toastify"
import {useNavigate, useParams } from "react-router-dom"
import FacultiesData from "./components/FacultiesData"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const tabs = ["School Data", "Submissions"]

const InstitutionPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [institution, setInstitution] = useState({});

  useEffect(() => {
    Institution.getSchoolDetails({school_id: params.id}).then((res) => {
      setInstitution(res.school);
    }).catch(err => {
      const resp = err.response?.data || {message: "Server error"};
      toast.error(resp.message, {autoClose: 2000});
      navigate("/institutions");
    })
  }, [navigate, params.id])

  return (
    <div className="feedback-page">
      <InstitutionPageHeader institution={institution}/>

      <Tab.Group vertical>
        <Tab.List className="flex bg-sky-100 sticky top-16 z-10">
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              className={({ selected }) =>
                classNames(
                  "py-2 px-4 text-sm font-medium",
                  selected
                    ? "bg-sky-800 text-sky-50"
                    : "hover:bg-sky-100 text-sky-900 hover:text-sky-700"
                )
              }
            >
              {tab}
            </Tab>
          ))}
        </Tab.List>

        <Tab.Panels className="bg-gray-100">
          <Tab.Panel>
            <FacultiesData school={{id: params.id, ...institution}}/>
          </Tab.Panel>
          <Tab.Panel>
            <Submissions />
          </Tab.Panel>
        </Tab.Panels>

      </Tab.Group>
    </div>
  )
}

export default InstitutionPage
