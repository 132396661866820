import { DocumentAddIcon } from "@heroicons/react/solid"
import { useState } from "react"
import { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { Admin } from "../../../api"
import FeedbackCard from "./components/FeedbackCard"

import "./Feedback.scss"

const AdminList = () => {
  const navigate = useNavigate();
  const [admins, setAdmins] = useState([]);

  useEffect(() => {
    Admin.allAdmins().then((res) => {
      setAdmins(res.admins);
    }).catch((err) => {
      const resp = err.response?.data || {message: "Server error"};
      toast.error(resp.message, {autoClose: 2000});
      navigate("/");
    })
  })

  return (
    <div className="feedbacks">
      <div className="head">
        <h1 className="h1">Admins</h1>

        <Link to="/admins/new" className="submit-feedback">
          <DocumentAddIcon className="h-5" />
          Add new admin
        </Link>
      </div>

      <div>
        <span className="text-lg text-gray-500">All Admins</span>

        <div className="cards">
          {
            admins.length > 0 &&
            admins.map((admin) => {
              return (
              <FeedbackCard
                superadmin={admin.superadmin}
                title={admin.fname + " " + admin.lname}
                time={admin.last_login}
              />
              )
            } )
          }
        
        </div>
      </div>
    </div>
  )
}

export default AdminList;
