import React, { useEffect, useState } from "react"

import FeedbackPageHeader from "./components/FeedbackPageHeader"
import "./FeedbackPage.scss"
import { Tab } from "@headlessui/react"
import Submissions from "./components/Submissions"
import { Feedback } from "../../../api"
import { toast } from "react-toastify"
import { useLocation, useNavigate } from "react-router-dom"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const tabs = ["Submissions"]

const FeedbackPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [feedback, setFeedback] = useState({});
  const feedbackId = location.pathname.split("/")[2];
  const [responses, setResponses] = useState([]);

  useEffect(() => {
    Feedback.getResponses({feedback_id: feedbackId}).then((res) => {
      setFeedback(res.feedback);
      setResponses(res.responses);
    }).catch(err => {
      const resp = err.response?.data || {message: "Server error"};
      toast.error(resp.message, {autoClose: 2000});
      navigate("/feedbacks")
    })
  })

  return (
    <div className="feedback-page">
      <FeedbackPageHeader feedback={feedback}/>

      <Tab.Group>
        <Tab.List className="flex bg-sky-100 sticky top-16 z-10">
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              className={({ selected }) =>
                classNames(
                  "py-2 px-4 text-sm font-medium",
                  selected
                    ? "bg-sky-800 text-sky-50"
                    : "hover:bg-sky-100 text-sky-900 hover:text-sky-700"
                )
              }
            >
              {tab}
            </Tab>
          ))}
        </Tab.List>

        <Tab.Panels className="bg-gray-100">
          <Tab.Panel>
            <Submissions />
          </Tab.Panel>
          {/* <Tab.Panel>
            Data
          </Tab.Panel> */}
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}

export default FeedbackPage
