import { BrowserRouter, Routes, Route } from "react-router-dom"
import { ToastContainer } from "react-toastify"

import "react-toastify/dist/ReactToastify.css"

import AuthLayout from "./layouts/AuthLayout"
import GeneralLayout from "./layouts/GeneralLayout"
import AdminLayout from "./layouts/AdminLayout"

import ChangePassword from "./pages/general/ChangePassword"
import Login from "./pages/general/Login"
import AdminDashboard from "./pages/admin/Dashboard"
import NotFound from "./pages/NotFound"
import FeedbackEditor from "./pages/admin/FeedbackEditor"
import AdminFeedbacks from "./pages/admin/AdminFeedbacks"
import FeedbackPage from "./pages/admin/FeedbackPage"
import FeedbackSubmissions from "./pages/admin/Submissions"

import Institutions from "./pages/admin/Institutions"
import InstitutionPage from "./pages/admin/InstitutionPage"
import AdminList from "./pages/admin/Admins"
import AddAdmin from "./pages/admin/Admins/AddAdmin"
import UsersList from "./pages/admin/Users"
import SubmissionDetailsPage from "./pages/admin/SubmissionDetails"
import UserProfile from "./pages/UserProfile"
import UsersSummary from "./pages/admin/UsersSummary"
import SubmissionsSummary from "./pages/admin/SubmissionsSummary"
import ContactMessages from "./pages/admin/Messages"
import MessageDetails from "./pages/admin/Messages/MessageDetails"

function App() {
  return (
    <BrowserRouter>
          <ToastContainer />
          <Routes>
            <Route element={<GeneralLayout />}>
            </Route>

            <Route element={<AuthLayout />}>
              <Route path="/login" element={<Login />} />
              <Route path="/change-password" element={<ChangePassword />} />
            </Route>

            <Route element={<AdminLayout />}>
              <Route path="/" element={<AdminDashboard />} />
              <Route path="/dashboard" element={<AdminDashboard />} />
              <Route path="/feedbacks" element={<AdminFeedbacks />} />
              <Route path="/feedbacks/:id" element={<FeedbackPage />} />
              <Route path="/feedbacks/:id/submissions" element={<FeedbackSubmissions />} />
              <Route path="/feedbacks/:id/submissions/:subID" element={<SubmissionDetailsPage />} />
              <Route path="/feedbacks/:id/edit" element={<FeedbackEditor />} />
              <Route path="/feedbacks/:id/summary" element={<SubmissionsSummary />} />
              
              <Route path="/institutions" element={<Institutions />} />
              <Route path="/institutions/:id" element={<InstitutionPage />} />
              
              <Route path="/admins" element={<AdminList />} />
              <Route path="/admins/new" element={<AddAdmin />} />

              <Route path="/users" element={<UsersList />} />
              <Route path="/users/summary" element={<UsersSummary />} />
              <Route path="/users/:id" element={<UserProfile />} />

              <Route path="/contact-messages/" element={<ContactMessages />} />
              <Route path="/contact-messages/:id" element={<MessageDetails />} />

              <Route path="*" element={<NotFound />} />
            </Route>

            <Route path="*" element={<NotFound />} />
          </Routes>
    </BrowserRouter>
  )
}

export default App
