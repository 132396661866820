import { Tab } from "@headlessui/react"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { Feedback } from "../../../api"
import { getRandomId } from "../../../utils/RandomID"

import Editor from "./components/Editor"
import JSONSchema from "./components/JSONSchema"
import Preview from "./components/Preview"
import "./FeedbackEditor.scss"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const tabs = ["Editor", "Preview", "JSON Schema"]

const FeedbackEditor = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const feedbackId = location.pathname.split("/")[2];
  const [feedback,  setFeedback] = useState({});
  const [feedbackQuestions,  setFeedbackQuestions] = useState([]);


  useEffect(() => {
    Feedback.getFeedbackQuestions({feedback_id: feedbackId, admin:true}).then((res) => {
      setFeedback(res.feedbackType);
    console.log(res.feedbackQuestions);
      res.feedbackQuestions.length >= 1 && setFeedbackQuestions(res.feedbackQuestions)
    }).catch((err) => {
      const resp = err.response?.data || {message: "Server error"};
      toast.error(resp.message, {autoClose: 2000});
      navigate("/feedbacks")
    })
  }, [])

  return (
    <Tab.Group>
      <Tab.List className="flex bg-sky-100 sticky top-16 z-10">
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            className={({ selected }) =>
              classNames(
                "py-2 px-4 text-sm font-medium",
                selected
                  ? "bg-sky-800 text-sky-50"
                  : "hover:bg-sky-100 text-sky-900 hover:text-sky-700"
              )
            }
          >
            {tab}
          </Tab>
        ))}
      </Tab.List>

      <Tab.Panels className="bg-gray-100">
        <Tab.Panel>
          <Editor
            desc={{...feedback, id: feedbackId}}
            setDesc={setFeedback}
            config={feedbackQuestions}
            setConfig={setFeedbackQuestions}
          />
        </Tab.Panel>
        <Tab.Panel>
          <Preview desc={feedback} config={feedbackQuestions} />
        </Tab.Panel>
        <Tab.Panel>
          <JSONSchema
            desc={feedback}
            setDesc={setFeedback}
            config={feedbackQuestions}
            setConfig={setFeedbackQuestions}
          />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  )
}

export default FeedbackEditor
