import { DocumentTextIcon, CheckIcon, TrashIcon } from "@heroicons/react/solid"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { Feedback, Message } from "../../../api"
import { toast } from "react-toastify"
import FeedbackDetails from "./Components/FeedbackDetails"

const MessageDetails = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [message, setMessage] = useState({});


  useEffect(() => {
    Message.getOne(params.id)
    .then(res => {
        console.log(res);
      setMessage(res.message);
    })
    .catch(err => {
      const error = err.response?.data || {message: "Network error. Go back a step and refresh."};
      toast.error(error.message);
      navigate("/");
    })

  }, [])

  const handleDelete = () => {
    toast.error("You cannot delete this message.")
  }


  return (
    <div className="feedbacks">
      
      <button onClick={() => navigate(-1)}><u>&lt;&lt;Go Back</u></button>
      
      <div className="head">
        <h1 className="h3">Message Details</h1>

      </div>


      {
        !message.msg_id ? 
        <p>Loading message...</p>
        :
        <FeedbackDetails message={message} />
      }

      <a href={`mailto:${message.user_email}`} className="w-full bg-cyan block text-center p-2 font-lg my-5">Respond</a>
        <div className="my-5 text-center">

        <button
            className="inline-flex items-center gap-1 bg-sky-100 text-sky-700 py-2 px-4 rounded-lg mr-5"
          >
            <CheckIcon />
            <small className="text-sky-800">Mark replied</small>
          </button>

          <button
            onClick={() => handleDelete()}
            className="inline-flex items-center gap-2 bg-red-100 text-red-700 py-2 px-4 rounded-lg"
          >
            <TrashIcon className="h-6" />
            <small className="text-red-800">Delete</small>
          </button>
        </div>
    </div>
  )
}

export default MessageDetails
