import { DocumentTextIcon, TrashIcon } from "@heroicons/react/solid"
import { Link, useNavigate, useParams } from "react-router-dom"
import "./Feedback.scss"
import { useEffect, useState } from "react"
import { Feedback } from "../../../api"
import { toast } from "react-toastify"
import FeedbackDetails from "./Components/FeedbackDetails"

const SubmissionDetailsPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [answers, setAnswers] = useState([]);
  const [feedback, setFeedback] = useState({});
  const [userType, setUserType] = useState("");
  const [user, setUser] = useState({});


  useEffect(() => {
    Feedback.getSubmissionAnswers({submission_id: params.subID})
    .then(res => {
      setUserType(res.userType);
      setUser(res.user);
      setAnswers(res.answers);
      setFeedback({type: res.feedback, date: res.date});
    })
    .catch(err => {
      const error = err.response?.data || {message: "Network error. Go back a step and refresh."};
      toast.error(error.message);
      navigate("/");
    })

  }, [])

  const handleDelete = () => {
    toast.error("You cannot delete this submission.")
  }

  const handleEdit = () => {
    toast.error("You cannot edit this submission.")
  }

  return (
    <div className="feedbacks">
      
      <button onClick={() => navigate(-1)}><u>&lt;&lt;Go Back</u></button>
      {/* <u>
        <Link to={`/feedbacks/${params.id}/submissions`}>&lt;&lt; Back to submissions</Link>
      </u> */}
      
      <div className="head">
        <h1 className="h3">Submission Responses</h1>

      </div>


      {
        answers.length < 1 ? 
        <p>Loading Answers...</p>
        :
        <FeedbackDetails answers={answers} feedback={feedback} userType={userType} user={user} />
      }

        <div className="my-5">
          <button
            onClick={() => handleEdit()}
            className="inline-flex items-center gap-2 bg-sky-100 text-sky-700 py-2 px-4 rounded-lg mr-5"
          >
            <DocumentTextIcon className="h-6" />
            <small className="text-sky-800">Edit Submission</small>
          </button>

          <button
            onClick={() => handleDelete()}
            className="inline-flex items-center gap-2 bg-red-100 text-red-700 py-2 px-4 rounded-lg"
          >
            <TrashIcon className="h-6" />
            <small className="text-red-800">Delete Submission</small>
          </button>
        </div>
    </div>
  )
}

export default SubmissionDetailsPage
