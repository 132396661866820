import { Dialog, Transition } from "@headlessui/react"
import { Fragment, useState } from "react"
import { toast } from "react-toastify";
import { Institution } from "../../../../api";

const DeleteFeedback = ({ deleteSchool, school_id, name, isOpen, closeModal }) => {
  const [reason, setReason] = useState("");

  // const deleteSchool = () => {
  //   Institution.deleteSchool({school_id, reason }).then((res) => {
  //     toast.error("Pressure ti waa", {autoClose: 2000})
  //     console.log("Pressure");
  //   }).catch((err) => {
  //     const resp = err.response?.data || {message: "Server error"};
  //     toast.error(resp.message, {autoClose: 2000});
  //   });
  // }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Delete {name}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">Why do you want to delete?</p>
                    <div className="mt-2">
                      <input
                        className="py-2 px-4 ring-1 ring-gray-200 w-full rounded-lg"
                        placeholder="State reason for deletion here..."
                        name="name"
                        value={reason}
                        onChange={ (e) => setReason(e.target.value) }
                      />
                    </div>
                  </div>

                  <div className="mt-4 space-x-2">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-50 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={() => {
                        closeModal()
                      }}
                    >
                      Cancel
                    </button>

                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-red-50 hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={() => deleteSchool(school_id, reason)}
                    >
                      Confirm
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default DeleteFeedback
