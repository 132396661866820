import { useEffect, useState } from "react"
import { Institution } from "../../../../api"
import dummyPhoto from "../../../../assets/image/default.png";
import { Link } from "react-router-dom";

const FeedbackDetails = ({answers, feedback, userType, user}) => {
  const [schools, setSchools] = useState([]);
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    Institution.getAll()
    .then(res => {
      setSchools(res.schools);
    })

    Institution.getAllDepartments()
    .then(res => {
      setDepartments(res.departments);
    })
  }, [])

  return (
    <div className="feedbackdetails">
      <h2 className="h4">{feedback.type}</h2>
      <img
        className="md:h-10 md:w-10 object-cover rounded-full"
        src={dummyPhoto}
        alt=""
        style={{marginBottom: "0px", padding: "0px"}}
      />
      <p
        style={{marginTop: "0px", padding: "0px"}}> 
        By: &nbsp;
        { userType === "anonymous" ? 
        <b>Anonymous User</b> : 
        <b>
          <u>
          <Link to={"/users/" + user.id}>{user.fname + " " + user.lname}</Link>
          </u> |  &nbsp;
          {schools.find(s => s.id === user.institution)?.name} |  &nbsp;
          { user.level + " Level"}
        </b>
        }
        <br />
        Submitted: <b>{feedback.date && new Date(feedback.date).toDateString() + ", " + new Date(feedback.date).toLocaleTimeString()}</b>
      </p>
      {
        answers &&
        answers.map(response => {
          return (
            <div className="feedbackblock">
              <FeedbackHead text={response.question} />

              <div className="detail">
                {
                  response.type === "institution" ?
                  schools.find(sch => sch.id === parseInt(response.answer))?.name
                  :
                  response.type === "department" ?
                  departments.find(dept => dept.id === parseInt(response.answer))?.name
                  :
                  response.answer
                }
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

const FeedbackHead = ({ text, required = false }) => (
  <div>
    <span className="text-lg tracking-tight font-semibold text-black">
      {text}
    </span>
    {required && <span className="text-rose-500 text-lg">*</span>}
  </div>
)

export default FeedbackDetails
