import "./MultiChoice.scss"

const MultiChoice = ({ config, disabled = false }) => {
  return (
    <div className="multichoice">
      {config.heading && <h5 className="h5 font-semibold">{config.heading}</h5>}

      <label>{config.label}</label>

      <div className="choice">
        {config.options.map((o) => (
          <div key={o}>
            <input type="checkbox" name={config.id} value={o} disabled={disabled} />
            <span>{o}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default MultiChoice
